import React from 'react';
import { Container } from 'semantic-ui-react';
import Head from 'next/head';
import Header from './Header';
import { withNamespaces, Link, i18n } from '../i18n';
import Footer from './Footer';
import CookieConsent from 'react-cookie-consent';

export default class Layout extends React.Component {
	render() {
		let title;
		let description;
		let keywords;
		let ogImage;

		switch (i18n.language) {
			case 'tr':
				title = "API Pazaryeri - Harika API'ler Bul | CollectAPI";
				description = "CollectAPI.com, API arayan kullanıcılar için bir pazaryeri olarak hizmet vermektedir.";
				keywords = "api, api pazaryeri, whatsapp api, geliştirici api, ücretsiz api, apiler, benzin fiyatı api, döviz api, eczane api, döviz api";
				ogImage = "https://cdnydm.com/permedia/AVZ2AolFRdexI7Bhbc4zSw.png?size=2446x1088"
				break;
			case 'en':
			default:
				title = "API Marketplace - Find Awesome APIs | CollectAPI";
				description = "CollectAPI.com, serves as a marketplace for users looking for API.";
				keywords = "api, api marketplace, whatsapp api, developer api, free api, apis, gasprice api, currency api, eczane api, döviz api";
				ogImage = "https://cdnydm.com/permedia/zt0p3I3ziAughlQFrSQRrQ.png?size=2322x1178"
				break;
		}

		return (
			
			<>
				<Head>
					<meta name="description" key="description" content={description} />
					<meta name="keywords" content={keywords} />
					<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
					<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
					<link rel="canonical" href="https://collectapi.com/" />
					<meta name="robots" content="all" />
					<meta name="website" property="og:type" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={description} />	
					<meta property="og:type" content="homepage" />
					<meta property="og:url" content="https://collectapi.com/" />
					<meta property="og:site_name" content="CollectAPI" />
					<meta name="author" content="CollectAPI" />
					 <meta
						property="og:image"
						content={ogImage}
					/> 
					 <meta property="og:image:alt" content={description} /> 
					 <meta
						name="Abstract"
						content={description}
					/> 

					<title key="title">{title}</title>
				</Head>
				<Header {...this.props} />
				<Container className="root-container" {...this.props.mainContainer}>
					{this.props.children}
				</Container>
				<Footer />
				<CookieConsent>{i18n.language === 'tr' ? 'Bu web sitesi, kullanıcı deneyimini geliştirmek için çerezleri kullanır.' : 'This website uses cookies to enhance the user experience.'}</CookieConsent>
			</>
		);
	}
}
